
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins } from "vue-property-decorator";
import { Row, Col, Image, Icon, Cell, NavBar } from "vant";
import ActDesc from "./Components/ActDesc.vue";
import navbar from "@/components/NavBar.vue";
@Component({
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [NavBar.name]: NavBar,
    ActDesc,
    navbar,
  },
})
export default class ClubWallet extends Mixins(Mixin) {
  public activityId = "";
  public applyInfo: any = {};
  created(): void {
    window.scrollTo(0, 0);
    this.activityId = String(this.$route.query.activityId);
    if (this.$route.query.sign === "true") {
      this.signIn();
    }
    this.getApplyDetails();
  }
  signIn(): void {
    this.$api.cmsApi.applyActivity.signIn(
      this.activityId,
      (data) => {
        if (data.data) {
          this.$toast("签到成功");
        }
      },
      (error) => {
        this.$toast(error);
      }
    );
  }
  getApplyDetails(): void {
    this.$api.cmsApi.applyActivity.findApplyActivityInfoMine(
      this.activityId,
      (data) => {
        if (
          this.ChannelModule.channel === "plat_ios" ||
          this.ChannelModule.channel === "plat_android"
        ) {
          this.jsBridge("isShowShare", "0");
        }
        this.applyInfo = data.data;
      }
    );
  }
  signStatusImg(info: any): string {
    let url = "";
    // if (info.activityStatus == "ACTIVITY_APPLY_STATUS_OVER") {
    //   url = require("@/assets/images/Wallet/chapter8.png");
    // } else
    if (info.signStatus === "1") {
      url = require("@/assets/images/EnterAct/signed@2x.png");
    } else if (info.signStatus === "2") {
      url = require("@/assets/images/Wallet/chapter7.png");
    }
    return url;
  }
  onRefresh(): void {
    this.getApplyDetails();
  }
  goDetail(): void {
    this.$router.push(`/apply-list?activityId=${this.applyInfo.activityId}`);
  }
  /* 
    返回上一页
    正常情况下返回上一页
    扫码进来的情况下 返回个人中心页
   */
  goBack(): void {
    if (this.$route.query.sign === "true") {
      this.$router.push("/mine");
    } else {
      this.$router.go(-1);
    }
  }
  formDate(date: string): string {
    const dt = new Date(Number(date));
    const y = dt.getFullYear();
    const m = (dt.getMonth() + 1 + "").padStart(2, "0");
    const d = (dt.getDate() + "").padStart(2, "0");
    const hh = (dt.getHours() + "").padStart(2, "0");
    const mm = (dt.getMinutes() + "").padStart(2, "0");
    // const ss = (dt.getSeconds() + "").padStart(2, "0");

    return `${y}.${m}.${d} ${this.getWeek(dt)}  ${hh}:${mm}`;
  }
  getWeek(date: Date): string {
    let week;
    if (date.getDay() == 0) week = "星期日";
    if (date.getDay() == 1) week = "星期一";
    if (date.getDay() == 2) week = "星期二";
    if (date.getDay() == 3) week = "星期三";
    if (date.getDay() == 4) week = "星期四";
    if (date.getDay() == 5) week = "星期五";
    if (date.getDay() == 6) week = "星期六";
    return String(week);
  }
}
