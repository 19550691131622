
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop, Watch, Vue } from "vue-property-decorator";
import navbar from "@/components/NavBar.vue";
import { Col, Row, Button, Field, Stepper, Dialog, NavBar } from "vant";
import ActDesc from "./Components/ActDesc.vue";
import wx from "weixin-js-sdk";
@Component({
  components: {
    navbar,
    ActDesc,
    [Col.name]: Col,
    [Row.name]: Row,
    [Button.name]: Button,
    [NavBar.name]: NavBar,
    [Field.name]: Field,
    [Stepper.name]: Stepper,
    [Dialog.Component.name]: Dialog.Component,
  },
})
export default class ClubSignUp extends Mixins(Mixin) {
  public activityId = "";
  public form = {
    applyCount: 1,
    contactsPhone: "",
    contactName: "",
    leaveMessage: "",
  };
  public activityInfo: cms.ApplyActivityResponse = {};
  public activityDescription: any = {};
  public isApply = false; // 是否已经报过名
  public showDialog = false;
  public dialogText = "";
  public type = "";
  public submitLoading = false;
  public docmHeight = document.documentElement.clientHeight; //默认屏幕高度
  public showHeight = document.documentElement.clientHeight; //实时屏幕高度
  phoneNumber = "";
  bodyHeight = "";
  scrollTop = 0;
  applyIntegral = 0;
  applyMoney = 0;
  created(): void {
    window.scrollTo(0, 0);
    let activityId = this.$route.query.activityId;
    this.activityId = activityId ? String(activityId) : "";
    if (this.activityId) {
      this.getData();
      this.getActDetail();
      this.getWechatMiniPhoneNumber();
    }
    document.addEventListener("visibilitychange", this.refreshPage);
  }
  refreshPage(): void {
    if (document.visibilityState == "visible") {
      let ua = this.getBrowserEnvironment();
      if (ua.ios) {
        this.getData();
      }
    }
  }
  getWechatMiniPhoneNumber(): void {
    this.$api.oauth2Api.token.getMemberInfoByToken(({ data }) => {
      this.phoneNumber = data.phone!;
    });
  }

  getData(): void {
    this.$api.cmsApi.applyActivity.findApplyActivityRegistered(
      this.activityId,
      ({ data }) => {
        if (
          this.ChannelModule.channel === "plat_ios" ||
          this.ChannelModule.channel === "plat_android"
        ) {
          this.jsBridge("isShowShare", "0");
        }

        this.isApply = false;
        this.activityInfo = data;
      },
      (error) => {
        this.isApply = true;
      }
    );
  }
  getActDetail(): void {
    this.$api.cmsApi.applyActivity.findApplyActivityByActivityId(
      this.activityId,
      (data) => {
        this.activityDescription = data.data;
        this.applyIntegral = this.activityDescription.applyIntegral;
        this.applyMoney = this.activityDescription.applyMoney;
      }
    );
  }
  changeNum(value: number): void {
    if (this.activityDescription.applyType == "4") {
      this.applyIntegral =
        (value *
          100 *
          ((typeof this.activityDescription.applyIntegral === "number"
            ? this.activityDescription.applyIntegral
            : parseFloat(this.activityDescription.applyIntegral)) *
            100)) /
        (100 * 100);
    }
    if (this.activityDescription.applyType == "3") {
      this.applyMoney =
        (value *
          100 *
          ((typeof this.activityDescription.applyMoney === "number"
            ? this.activityDescription.applyMoney
            : parseFloat(this.activityDescription.applyMoney)) *
            100)) /
        (100 * 100);
    }
  }
  submitApply(activityId: string | undefined): void {
    let params = {
      activityId: this.activityId,
      applicantName: this.form.contactName,
      applyNum: this.form.applyCount,
      leaveMessage: this.form.leaveMessage,
    };
    if (!this.isApply) {
      if (!this.form.contactName) {
        this.$toast.fail("请填写姓名");
        return;
      }
      if (
        this.activityInfo.leaveMessageRequired === "1" &&
        this.form.leaveMessage === ""
      ) {
        this.$toast.fail("请填写留言");
        return;
      }
    } else {
      // this.$toast("报过了");
      this.type = "01";
      this.dialogText = "本活动每名用户只能报名一次，您已经提交过报名信息。";
      this.showDialog = true;
    }
    this.submitLoading = true;
    this.$api.cmsApi.applyActivity.saveApplyActivityRegistered(
      params,
      ({ data }) => {
        if (data) {
          this.submitLoading = false;
          // 免费活动 购票兑换 积分兑换 或者支付金额为 0
          if (this.activityDescription.applyType != "3" || data.payPrice == 0) {
            this.$router.push(
              `/apply-success?activityId=${String(activityId)}`
            );
          } else {
            if (data.orderId) {
              if (this.ChannelModule.channel === "plat_h5") {
                this.$router.replace({
                  path: "/payconfirm",
                  query: {
                    orderId: `${data.orderId!}`,
                    orderType: "ACTIVITYAPPLY",
                    activityId: `${String(activityId)}`,
                  },
                });
              } else if (this.ChannelModule.channel === "plat_wechat_miniapp") {
                // TODO 调起小程序支付
                let params = {
                  actuallyPaidAmt: data.payPrice,
                  orderExpireTime: data.orderExpireTime,
                  orderId: data.orderId,
                  orderType: "ACTIVITYAPPLY",
                };
                wx.miniProgram.redirectTo({
                  url: `/pagesOrder/confirmPayment/confirmPayment?par=${JSON.stringify(
                    params
                  )}`,
                });
              } else if (
                this.ChannelModule.channel === "plat_ios" ||
                this.ChannelModule.channel === "plat_android"
              ) {
                // 调起app支付
                this.jsBridge(
                  "paymentActivity",
                  `orderId/${data.orderId},actuallyPaidAmt/${
                    data.payPrice ? data.payPrice.toFixed(2) : 0
                  },orderExpireTime/${
                    data.orderExpireTime
                  },orderType/ACTIVITYAPPLY,activityId/${activityId}`
                );
              }
            }
          }
        } else {
          this.submitLoading = false;
        }
      },
      (error) => {
        this.submitLoading = false;
        error && this.$toast(error);
      }
    );
  }
  onCloseDialog(type: string): void {
    if (type === "01") {
      this.$router.push("/enter-activity");
    } else if (type === "02") {
      this.$router.replace("/enter-activity");
    }
  }
  /*
   * 俱乐部活动服务协议
   */
  shopTerms(): void {
    let name = "《俱乐部活动服务协议》";
    let type = "ACTIVITY_SERVE_CLAUSE";
    this.$router.push(`/personal-data/notice?name=${name}&type=${type}`);
  }
  beforeDestroy(): void {
    document.removeEventListener("visibilitychange", this.refreshPage);
  }
}
