var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"ClubList"},[_c('div',{staticClass:"detail-top"},[_c('div',{staticClass:"cover-mask1"}),_c('div',{staticClass:"cover-mask2"}),_c('div',{staticClass:"cover-mask-img",style:({
        'background-image': `url(${_vm.activityInfo.activityImageUrl})`,
      })})]),(_vm.ChannelModule.channel === 'plat_h5')?_c('van-nav-bar',{staticClass:"navbar",attrs:{"title":"入选名单","left-arrow":""},on:{"click-left":function($event){return _vm.$router.go(-1)}}}):_vm._e(),_c('van-row',{staticClass:"container",style:(_vm.ChannelModule.channel === 'plat_h5'
        ? `padding-top:1.08rem`
        : `padding-top:0`)},[_c('van-row',{staticClass:"header",style:(_vm.ChannelModule.channel === 'plat_h5'
          ? `padding-top:0.78rem`
          : `padding-top:1.68rem`)},[_c('h3',{staticClass:"h-one"},[_vm._v("俱乐部活动")]),_c('h3',{staticClass:"h-two"},[_vm._v("入选名单")])]),_c('van-row',{staticClass:"club-details"},[_c('ActDesc',{attrs:{"activity-name":_vm.activityInfo.activityName,"activity-address":_vm.activityInfo.siteName,"act-start-time":_vm.activityInfo.applyStartTime}}),_c('van-row',{staticClass:"line"}),_c('van-row',{staticClass:"detail-wrapper"},[_c('h4',[_vm._v("恭喜以下"+_vm._s(_vm.total)+"位用户入选")]),_c('van-col',{staticClass:"d-header"},[_c('h5',{staticClass:"d-h-one"},[_vm._v("序号")]),_c('h5',{staticClass:"d-h-two"},[_vm._v("用户昵称")])]),_c('van-list',{staticClass:"d-list",attrs:{"finished":_vm.list.finished,"immediate-check":false},on:{"load":_vm.getList},model:{value:(_vm.list.loading),callback:function ($$v) {_vm.$set(_vm.list, "loading", $$v)},expression:"list.loading"}},_vm._l((_vm.RosterList),function(item,index){return _c('van-col',{key:index,staticClass:"d-item"},[_c('span',{staticClass:"orderNum"},[_vm._v(_vm._s(index + 1))]),_c('span',{staticClass:"nikeName"},[_vm._v(" "+_vm._s(item.applicantMobile)+" ")]),(item.crtMemberFlag)?_c('span',{staticClass:"me-sign"},[_vm._v("Me")]):_vm._e()])}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }