
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins } from "vue-property-decorator";
import {
  Col,
  Row,
  Button,
  Field,
  Icon,
  Image,
  Dialog,
  NavBar,
  Toast,
} from "vant";
import ActDesc from "./Components/ActDesc.vue";
import { ChannelType } from "@/store/modules/channel";
@Component({
  components: {
    ActDesc,
    [Col.name]: Col,
    [Row.name]: Row,
    [Button.name]: Button,
    [Field.name]: Field,
    [Icon.name]: Icon,
    [Image.name]: Image,
    [NavBar.name]: NavBar,
    [Dialog.Component.name]: Dialog.Component,
  },
})
export default class ClubSignUpDetail extends Mixins(Mixin) {
  public activityId = "";
  public applyInfo: any = {};
  showTips = false;
  showBtn = true;
  get getTitle(): string {
    let title = "";
    // applyStatus报名状态 applyIsCheck 1 是 0 否需要审核
    if (
      this.applyInfo.applyStatus === "0" &&
      this.applyInfo.applyIsCheck === "1"
    ) {
      // 需要审核 待审核
      this.showBtn = true;
      title = "报名成功，等待公布结果";
    } else if (this.applyInfo.applyStatus === "1") {
      // 已入选
      this.showBtn = true;
      title = "恭喜您，已入选";
    } else if (this.applyInfo.applyStatus === "2") {
      // 未入选
      this.showBtn = false;
      title = "很遗憾，未入选";
    } else if (this.applyInfo.applyStatus === "3") {
      this.showBtn = false;
      title = "很遗憾，已取消";
    }

    return title;
  }
  get payPrice(): string {
    let price = "";
    // 付款活动
    if (this.applyInfo.applyType == "3") {
      price = this.applyInfo.payPrice + "元";
    } else if (this.applyInfo.applyType == "4") {
      //积分兑换
      price = this.applyInfo.payPrice + "积分";
    }
    return price;
  }
  created(): void {
    let activityId = this.$route.query.activityId;
    this.activityId = activityId ? String(activityId) : "";
    window.scrollTo(0, 0);
    if (this.activityId) {
      this.getApplyDetails();
    }
  }
  // goback(): void {
  //   let type = "";
  //   if (
  //     this.applyInfo.applyStatus === "0" ||
  //     this.applyInfo.applyIsCheck === "1"
  //   ) {
  //     // 【我的活动-待审核】
  //     type = "0";
  //   } else if (this.applyInfo.applyStatus === "1") {
  //     // 返回到【我的活动-已入选
  //     type = "1";
  //   } else if (this.applyInfo.applyStatus === "2") {
  //     // 返回到【我的活动-未入选】
  //     type = "2";
  //   }
  //   this.$router.replace({
  //     path: "/enter-activity",
  //     query: {
  //       isAll: `${false}`,
  //       selectType: type,
  //     },
  //   });
  // }
  getApplyDetails(): void {
    this.$api.cmsApi.applyActivity.findApplyActivityInfoMine(
      this.activityId,
      (data) => {
        if (
          this.ChannelModule.channel === "plat_ios" ||
          this.ChannelModule.channel === "plat_android"
        ) {
          this.jsBridge("isShowShare", "0");
        }
        this.applyInfo = data.data;
        window.document.title = this.getTitle;
      }
    );
  }
  cancelApply(): void {
    this.$api.oauth2Api.token.h5Token({ code: "" }, ({ data }) => {
      let code = data.code;
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
      });
      this.$api.cmsApi.applyActivity.cancelRegister(this.activityId, (data) => {
        Toast.clear();
        if (data.data) {
          if (this.ChannelModule.channel !== "plat_h5") {
            // 跳转需要加 code 否则会跳转登录
            this.$router.push(`/externalaccess/club?code=${code}`);
          } else {
            this.$router.push("/enter-activity");
          }
        }
      });
    });
  }
  getCode(): string {
    let code = "";
    this.$api.oauth2Api.token.h5Token({ code: "" }, ({ data }) => {
      code = data.code!;
      this.ChannelModule.SET_channel_PERSIST(data.channel as ChannelType);
      this.UserModule.SET_ACCESS_TOKEN_PERSIST(data.accessToken!);
      this.UserModule.SET_IS_LOGIN_PERSIST(true);
    });
    return code;
  }
  goWallet(): void {
    this.$router.push(`/apply-wallet?activityId=${this.activityId}`);
  }
  formDate(date: string): string {
    const dt = new Date(Number(date));
    const y = dt.getFullYear();
    const m = (dt.getMonth() + 1 + "").padStart(2, "0");
    const d = (dt.getDate() + "").padStart(2, "0");
    const hh = (dt.getHours() + "").padStart(2, "0");
    const mm = (dt.getMinutes() + "").padStart(2, "0");
    const ss = (dt.getSeconds() + "").padStart(2, "0");

    return `${y}.${m}.${d} ${hh}:${mm}:${ss}`;
  }
}
