
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Row, Col, Button, Image, List, NavBar } from "vant";
import navbar from "@/components/NavBar.vue";
import ActDesc from "./Components/ActDesc.vue";
@Component({
  components: {
    navbar,
    ActDesc,
    [Row.name]: Row,
    [Col.name]: Col,
    [Button.name]: Button,
    [Image.name]: Image,
    [List.name]: List,
    [NavBar.name]: NavBar,
  },
})
export default class ClubRoster extends Mixins(Mixin) {
  public RosterList: Array<cms.ActivityHistoryDto> = [];
  public activityId = "";
  public activityInfo: cms.ApplyActivityResponse = {};
  public list = {
    loading: false,
    finished: false,
  };
  public page = {
    current: 1,
    size: 10,
  };
  public total = 0;
  mounted(): void {
    window.scrollTo(0, 0);
    this.activityId = String(this.$route.query.activityId);
    this.getActDetail();
    this.getList(true);
  }
  getActDetail(): void {
    this.$api.cmsApi.applyActivity.findApplyActivityByActivityId(
      this.activityId,
      (data) => {
        if (
          this.ChannelModule.channel === "plat_ios" ||
          this.ChannelModule.channel === "plat_android"
        ) {
          this.jsBridge("isShowShare", "0");
        }
        this.activityInfo = data.data;
      }
    );
  }
  getList(first: boolean): void {
    if (first) {
      this.page.current = 1;
    } else {
      this.page.current++;
    }
    this.list.loading = true;
    this.$api.cmsApi.applyActivity.findApplyActivityFinalist(
      this.activityId,
      {
        page: this.page.current,
        size: this.page.size,
      },
      ({ data }) => {
        this.total = data.total!;
        if (this.page.current === 1) {
          this.RosterList = [];
          this.RosterList = data.records!;
        } else {
          this.RosterList = this.RosterList.concat(data.records!);
        }
        this.list.loading = false;
        this.list.finished = data.current! >= data.pages!;
      }
    );
  }
}
