
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Row, Col } from "vant";
@Component({
  components: { [Row.name]: Row, [Col.name]: Col },
})
export default class EnterAct extends Mixins(Mixin) {
  @Prop({ default: "", type: String }) public actType!: string;
  @Prop({ default: 0, type: Number }) public actStartTime!: number;
  @Prop({ default: 0, type: Number }) public actEndTime!: number;
  @Prop({ default: "", type: String }) public activityAddress!: string;
  @Prop({ default: "", type: String }) public activityName!: string;
  formDate(date: string): string {
    const dt = new Date(Number(date));
    const y = dt.getFullYear();
    const m = (dt.getMonth() + 1 + "").padStart(2, "0");
    const d = (dt.getDate() + "").padStart(2, "0");
    const hh = (dt.getHours() + "").padStart(2, "0");
    const mm = (dt.getMinutes() + "").padStart(2, "0");
    // const ss = (dt.getSeconds() + "").padStart(2, "0");

    return `${y}.${m}.${d} ${this.getWeek(dt)}  ${hh}:${mm}`;
  }
  getWeek(date: Date): string {
    let week;
    if (date.getDay() == 0) week = "星期日";
    if (date.getDay() == 1) week = "星期一";
    if (date.getDay() == 2) week = "星期二";
    if (date.getDay() == 3) week = "星期三";
    if (date.getDay() == 4) week = "星期四";
    if (date.getDay() == 5) week = "星期五";
    if (date.getDay() == 6) week = "星期六";
    return String(week);
  }
}
